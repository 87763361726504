import { FilmComponent } from "../FilmComponent/FilmComponent"
import { GalleryComponent2 } from "../Gallery/GalleryComponent2"
import { mayPhotos } from "../Gallery/LabPhotos/may/mayPhotos"
import video1 from "../../assets/labPhotos/may/video1.mp4"

import "./LabText.scss"

export const May = () => {
	return (
		<div className='may'>
			<h2 className='LabTitle'>maj 2024</h2>
			<p className='LabText2'>
				Uczniowie nadal kształcili swoje umiejętności w konstruowaniu z klocków
				Lego oraz Modi wykorzystując instrukcję, a także inwencję własną, Na
				zajęciach rewalidacyjnych wykorzystywaliśmy kamerę oraz aparat,
				nagrywając filmy, robiąc zdjęcia. Uczniowie klasy szóstej mieli
				możliwość nauki programowania różnych przedmiotów oraz ich drukowania na
				drukarce 3D. Wydrukowane przedmioty zostały wręczone mamom z okazji ich
				Święta.
			</p>
			<GalleryComponent2 carouselId='mayPhotos' images={mayPhotos} />
			<FilmComponent videoPath={video1} />
		</div>
	)
}
