import { GalleryComponent2 } from "../Gallery/GalleryComponent2"
import { junePhotos } from "../Gallery/LabPhotos/june/junePhotos"

import "./LabText.scss"

export const June = () => {
	return (
		<div className='june'>
			<h2 className='LabTitle'>czerwiec 2024</h2>
			<p className='LabText2'>
				UcW tym miesiącu korzystaliśmy ze sprzętu który umożliwiał nagrywanie i
				robienie zdjęć, okazji ku temu było wiele. Robiliśmy zdjęcia na
				wycieczce i apelu, zwracając uwagę na dobre ustawienie oraz tło. Kamerę
				i aparat wykorzystaliśmy także przy okazji festynu z okazji Dnia
				Dziecka. Nagrywaliśmy rozmówki z języka angielskiego, gdzie uczniowie
				prezentowali swoje umiejętności językowe. Korzystaliśmy także z drukarki
				3D. Uczniowie zaprogramowali i wydrukowali breloczki ze swoim imieniem w
				języku niemieckim.
			</p>
			<GalleryComponent2 carouselId='junePhotos' images={junePhotos} />
		</div>
	)
}
