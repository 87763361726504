import { FilmComponent } from "../FilmComponent/FilmComponent"
import { GalleryComponent2 } from "../Gallery/GalleryComponent2"
import { november24Photos } from "../Gallery/LabPhotos/november24/november24Photos"
import video1 from "../../assets/labPhotos/november24/video1.mp4"
import video2 from "../../assets/labPhotos/november24/video2.mp4"
import video3 from "../../assets/labPhotos/november24/video3.mp4"
import "./LabText.scss"

export const November24 = () => {
	return (
		<div className='november24'>
			<h2 className='LabTitle'>rok szkolny 2024/25</h2>
			<p className='LabText2'>
				Pomoce dydaktyczne zakupione w ramach programu Laboratorium Przyszłości,
				są wykorzystywane na obowiązkowych zajęciach edukacyjnych oraz zajęciach
				indywidualnych.
			</p>
			<p className='LabText2'>
				Wszyscy uczniowie chętnie korzystają z zestawów klocków Lego. Budują
				wspaniałe modele zgodnie z instrukcją, a także według własnych pomysłów.
				Uczą się w ten sposób logicznego myślenia, kreatywnego projektowania i
				programowania. Rozwijają i usprawniają swoje zdolności manualne oraz
				wyobraźnię przestrzenną.
			</p>
			<p className='LabText2'>
				Najczęściej wykorzystywany jest aparat fotograficzny. Służy on do
				dokumentowania działań realizowanych w placówce: uroczystości i imprez
				szkolnych, apeli, ciekawych lekcji, dokumentowane są także ciekawe
				wytwory uczniów.
				<br></br>
				Zdjęcia te na bieżąco są umieszczane na faceboku.
			</p>
			<GalleryComponent2
				carouselId='november24Photos'
				images={november24Photos}
			/>
			<FilmComponent videoPath={video1} />
			<FilmComponent videoPath={video2} />
			<FilmComponent videoPath={video3} />
		</div>
	)
}
